<template>
  <div class="selected-video">
    <video class="video-player" 
           @ended="addWatchHistory"
           :src="selectedVideo.videoUrl"
           controls autoplay controlsList="nodownload">
    </video>
    <div class="selected-video__title">
      {{ selectedVideo.title }}
    </div>
    <div class="selected-video__description">
      {{ selectedVideo.description }}
    </div>
  </div>
</template>

<script>

export default {
  name: "TrainingSelectedVideo",
  props: {
    selectedVideo: {
      type: Object,
      default: () => {},
    },
  },
  methods: {
    addWatchHistory() {
      this.$http
        .put(`/api/v1/training-video/${this.selectedVideo.id}/seen`)
        .then(() => {
          this.selectedVideo.isSeen = true;
        })
        .catch(() => {
          this.pushAlert(
            "error",
            "Something went wrong"
          )
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.selected-video {
  &__title {
    color: #1a1a1a;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    margin-bottom: 8px;
    margin-top: 16px;
  }
  &__description {
    opacity: 0.7;
    color: #1a1a1a;
    font-size: 16px;
    letter-spacing: 0;
    line-height: 24px;
  }
}
.video-player {
  width: 100%;
  height: 600px;
  border: 1px solid #8f8a8a;
}
.video-player-box {
  &::v-deep {
    .video-js {
      width: 100%;
      height: 600px;
    }
    .vjs-big-play-button {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
